import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const { Image } = BaseArticleTeaserBodytextTheme;

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    category: `hidden`,
    content: ``,
    description: `sm:text-headline-md`,
    footer: `hidden`,
    headline: `text-headline-sm`,
  },
  variants: {
    variant: {
      third: {
        content: `border-l-secondary-700 bg-gray-100`,
      },
    },
  },
  defaultVariants: {
    variant: 'third',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
