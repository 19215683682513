import { VideoReels } from '@/components/VideoReels';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';

export const LabradorVideoReels: LabradorComponent = ({ type, data, meta }) => {
  const { playlistId, scriptSrc } = data ?? {};
  const [{ isNativeAd }] = useAppState();
  const isInvalid = isNativeAd || !playlistId || !scriptSrc;

  if (isInvalid) {
    return null;
  }

  return <VideoReels playlistId={playlistId} scriptSrc={scriptSrc} />;
};
