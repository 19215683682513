import { HighlightedArticles } from '@/components/HighlightedArticles';
import { LabradorComponent } from '@/types/component';
import { logger } from '@/utils/logger';
import { StandaloneHighlightedArticlesProps } from 'base/components/HighlightedArticles';
import { getHighlightedArticles } from 'lib/data/highlightedArticles';
import { getMostReadArticlesIds } from 'lib/data/mostRead';
import { fetchArticlesData } from 'lib/labrador/fetchArticlesData';
import { useState } from 'react';
import { useAsync } from 'react-use';

export const LabradorHighlightedArticles: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  const [articlesData, setArticlesData] = useState<
    | {
        articles?: StandaloneHighlightedArticlesProps['articles'];
        showBlip?: boolean;
        isLoading?: boolean;
      }
    | undefined
  >({ isLoading: data.isEnabled });

  useAsync(async () => {
    if (!data.isEnabled) return;

    try {
      const highlightedArticles = await getHighlightedArticles(data.title);

      if (highlightedArticles?.length) {
        setArticlesData({ articles: highlightedArticles, showBlip: true, isLoading: false });
      } else {
        const mostReadArticleIds = await getMostReadArticlesIds();
        const mostReadArticles = (await fetchArticlesData(mostReadArticleIds.map(String)))?.result
          ?.map((article: any) => {
            return {
              headline: article.title,
              label: 'Mest läst',
              link: { href: article.published_url },
            };
          })
          .slice(0, 5);
        setArticlesData({ articles: mostReadArticles, showBlip: false, isLoading: false });
      }
    } catch (err) {
      logger.error(err);
      setArticlesData({ isLoading: false });
    }
  }, []);

  if (!data.isEnabled) {
    return null;
  }

  return <HighlightedArticles {...articlesData} {...props} />;
};
