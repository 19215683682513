import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseArticleHeaderTheme from 'base/components/ArticleHeader/theme';

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      text: `text-primary-700 group-data-[category*=nyheter]:text-secondary-700 group-data-[hostpath*=nyheter]:text-secondary-700`,
    },
    size: {
      text: 'sm:text-headline-sm',
    },
    variant: {
      text: 'inline-flex capitalize',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  variants: {
    variant: {
      first: {
        base: `group mx-auto grid w-full max-w-content-lg grid-cols-[auto_1fr] gap-x-3 gap-y-0`,
        bylines: `mt-1.5 flex-wrap gap-3`,
        description: `text-preamble-sm w-full sm:text-preamble-md`,
        headline: `mb-3 text-headline-lg sm:text-headline-xl`,
        media: `my-6 [&_img]:aspect-default [&_img]:object-cover [&_picture]:-mx-3 [&_picture]:aspect-default [&_picture]:w-[calc(100%_+_24px)] sm:[&_picture]:-mx-4.5 sm:[&_picture]:w-[calc(100%_+_36px)] md:[&_picture]:mx-0 md:[&_picture]:w-full`,
        tags: `order-1 col-span-2 mb-1.5 flex gap-3 sm:mb-2`,
        date: `text-input-sm sm:text-input-md col-span-1 text-gray-600`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleHeader, { Tag });
