import { LabradorComponent } from '@/types/component';

import { LabradorBox } from 'base/components/labrador/Box';

export const HantLabradorBox: LabradorComponent = (props) => {
  const { backgroundColor } = props?.meta ?? {};

  ['mobile', 'desktop'].forEach((key) => {
    if (backgroundColor?.[key]?.includes('white')) {
      delete backgroundColor[key];
    }
  });

  return <LabradorBox {...props} />;
};
