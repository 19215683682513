import { ButtonWith } from '@/components/Button';
import { TimerWith } from '@/components/Timer';
import { useClassName, useTheme } from '@/styles/CourseCountdown';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div', styles: { key: '' } });
const Logo = GenericComponent({ as: 'picture', styles: { key: 'logo' } });
const Timer = TimerWith({ theme: { useTheme } });
const Description = GenericComponent({ as: 'p', styles: { key: 'description' } });
const Button = ButtonWith({ theme: { useTheme } });

export const CourseCountdown = Object.assign(Base, {
  Logo,
  Timer,
  Description,
  Button,
});
