import { tw } from '@/utils/tw';
import RadioButtonTheme from 'base/components/RadioButton/theme';

const Checkbox = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    label: ['text-input-md', 'ml-2.5'],
    input: [
      'active:outline-0',
      'active:shadow-black',
      'appearance-none',
      'before:-z-1',
      'before:absolute',
      'before:active:bg-gray-100',
      'before:bg-white',
      'before:block',
      'before:h-full',
      'before:left-0',
      'before:rounded-full',
      'before:top-0',
      'before:transition-colors',
      'before:w-full',
      'bg-clip-content',
      'checked:bg-black',
      'focus:outline-4',
      'focus:shadow-inset-2',
      'focus:shadow-secondary-700',
      'h-5',
      'hover:border-1',
      'hover:outline-4',
      'hover:shadow-black',
      'hover:shadow-inset-2',
      'outline-0',
      'outline-secondary-200',
      'outline',
      'p-1.25',
      'relative',
      'rounded-full',
      'shadow-black',
      'shadow-inset-1',
      'transition-all',
      'w-5',
    ],
  },
});

export default Checkbox;
