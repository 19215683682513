import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    content: `gap-4`,
    button: '[&>a>svg]:-mt-0.5 [&>a]:pt-3',
    headline: '-mt-4 text-headline-lg md:text-headline-xl',
    description: 'text-body-sm md:text-body-md',
  },
});

export default Object.assign(ShowHero, { Tag });
