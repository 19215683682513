import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
});

const ArticleFooter = tw.theme({
  slots: {
    base: `flex w-full flex-col gap-4`,
    tags: `mx-auto flex w-full flex-wrap justify-start gap-3 md:max-w-article-lg`,
    line: `mb-5.5 h-px w-full bg-primary-200`,
  },
  variants: {
    center: {
      true: {
        tags: `justify-center`,
      },
    },
  },
});

export default Object.assign(ArticleFooter, { Tag });
