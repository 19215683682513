import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';

export const getMostReadArticlesIds = async () => {
  const url = getUrl('/mostRead', getRecommendationMicroservice());

  if (!url) {
    return [];
  }

  url?.searchParams.set('brand', getSite());
  url?.searchParams.set('limit', '10');

  const response = await http.get<number[]>(url.href);
  const articleIds = response?.data ?? [];
  return articleIds;
};
