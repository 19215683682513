import { ButtonWith } from '@/components/Button';
import { TimerWith } from '@/components/Timer';
import { useClassName, useTheme } from '@/styles/Confirmation';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'section', styles: { key: '' } });
const Title = GenericComponent({ as: 'h2', styles: { key: 'title' } });
const Banner = GenericComponent({ as: 'div', styles: { key: 'banner' } });
const BannerHeader = GenericComponent({ as: 'h3', styles: { key: 'banner_header' } });
const BannerCaption = GenericComponent({ as: 'p', styles: { key: 'banner_caption' } });
const BannerContacts = GenericComponent({ as: 'p', styles: { key: 'banner_contacts' } });
const BannerTimer = TimerWith({ theme: { useTheme } });
const Button = ButtonWith({ theme: { useTheme } });

export const Confirmation = Object.assign(Base, {
  Banner: Object.assign(Banner, {
    Caption: BannerCaption,
    Header: BannerHeader,
    Contacts: BannerContacts,
    Timer: BannerTimer,
  }),
  Button,
  Title,
});
