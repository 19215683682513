import { LabradorComponent } from '@/types/component';
import { LabradorArticleHeader } from 'base/components/labrador/ArticleHeader';

export const HantLabradorArticleHeader: LabradorComponent = ({ type, data, meta, descendants }) => {
  const { prominentTag } = data;

  return (
    <LabradorArticleHeader {...{ type, meta, descendants }} data={{ ...data, tags: [prominentTag].filter(Boolean) }} />
  );
};
