import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    variant: {
      text: `normal-case hover:underline`,
    },
    size: {
      text: 'text-body-sm',
    },
    colors: {
      text: ``,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    base: ``,
    headline: `mb-0 border-none pb-0 text-headline-lg md:text-headline-xl`,
    group: ``,
    letter: `text-headline-md md:text-headline-lg`,
    tags: ``,
  },
});

export default Object.assign(TagsIndex, { Tag });
