import { tw } from '@/utils/tw';
import BaseTextSubtitleTheme from 'base/components/TextSubtitle/theme';

const TextSubtitle = tw.theme({
  extend: BaseTextSubtitleTheme,
  base: [
    'data-[page-type=front]:mx-auto',
    'data-[page-type=front]:max-w-tabloid',
    'data-[page-type=front]:w-full',
    'text-headline-md',
    'md:text-headline-lg',
  ],
});

export default TextSubtitle;
