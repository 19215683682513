import { ButtonProps } from '@/components/Button';
import { TimerProps } from '@/components/Timer';
import { useStableProps } from '@/hooks/useStable';
import { ClassNameProvider } from '@/styles/CourseCountdown';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CourseCountdown } from './CourseCountdown';

export interface StandaloneCourseCountdownProps extends StandaloneComponentProps<typeof CourseCountdown> {
  logo?: ReactNode;
  timer?: TimerProps;
  description?: ReactNode;
  button?: ButtonProps;
}

export const StandaloneCourseCountdown: StandaloneComponent<StandaloneCourseCountdownProps> = ({
  logo,
  timer,
  button,
  description,
  options,
  ...props
}) => {
  const { $button, $timer, $logo, $description, ...$base } = options ?? {};

  const stableButtonProps = useStableProps({ options: $button }, button);
  const stableTimerProps = useStableProps({ options: $timer }, timer);

  return (
    <ClassNameProvider value={$base}>
      <CourseCountdown {...$base} {...props}>
        {logo && <CourseCountdown.Logo {...$logo}>{logo}</CourseCountdown.Logo>}
        <CourseCountdown.Timer {...stableTimerProps} />
        {description && <CourseCountdown.Description {...$description}>{description}</CourseCountdown.Description>}
        <CourseCountdown.Button {...stableButtonProps} />
      </CourseCountdown>
    </ClassNameProvider>
  );
};
