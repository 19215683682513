import { useStableProps } from '@/hooks/useStable';
import { StandaloneArticleHeader } from 'base/components/ArticleHeader';
import { isString } from 'typesafe-utils';

const extractSubcategory = (category: string | undefined) =>
  category && category.includes('/') ? category.split('/')[1] : category;

export const HantStandaloneArticleHeader: typeof StandaloneArticleHeader = ({ category, ...props }) => {
  const stableProps = useStableProps(props, {
    category: {
      url: category?.url,
      value: isString(category?.value) ? extractSubcategory(category?.value) : category?.value,
    },
  });

  return <StandaloneArticleHeader {...stableProps} />;
};
