import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { StandaloneHighlightedArticlesProps } from 'base/components/HighlightedArticles';

export const getHighlightedArticles = async (
  label: string,
): Promise<StandaloneHighlightedArticlesProps['articles']> => {
  const siteId = process.env.OCELOT_LABRADOR_SITE_ID;
  const api = process.env.OCELOT_LABRADOR_API;
  const request = getUrl('/article', api);

  if (!request) return [];

  request.searchParams.set(
    'query',
    `visibility_status:P AND isHighlighted:(1) AND lab_site_id:${siteId} AND highlighted_start_date:[* NOW] AND highlighted_end_date:[NOW *]`,
  );
  request.searchParams.set('order', 'desc');
  request.searchParams.set('orderBy', 'highlighted_start_date');

  const response = await http.get(request.href);

  const articles: NonNullable<StandaloneHighlightedArticlesProps['articles']> = (response?.data?.result ?? []).map(
    (article: any) => ({
      headline: article.highlightedHeadline || article.title,
      label,
      link: {
        href: article.published_url,
      },
    }),
  );

  return articles;
};
