/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "quote_variant_primary": "italic",
  "quote_variant_secondary": "italic",
  "quote_size_default": "text-headline-md sm:text-headline-lg",
  "quote_colors_default": "",
  "quotationMark_variant_primary": "hidden",
  "quotationMark_variant_secondary": "hidden",
  "quotationMark_size_default": "mb-4",
  "quotationMark_colors_default": "",
  "author_variant_primary": "hidden",
  "author_variant_secondary": "hidden",
  "author_size_default": "text-headline-xs mt-3",
  "author_colors_default": "",
  "variant_primary": "relative text-center before:block before:absolute before:left-0 before:top-0",
  "variant_secondary": "relative flex items-start text-left before:block before:absolute before:left-0 before:top-0",
  "size_default": "w-full px-6 before:w-1 before:h-full",
  "colors_default": "before:bg-primary-700"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Quotebox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;