import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import NextImage from 'next/image';
import { ReactNode, useRef } from 'react';
import { Video } from '../JwVideo/Video/Video';
import { ShowHero } from './ShowHero';

export interface StandaloneShowHeroProps extends StandaloneComponentProps<typeof ShowHero> {
  headline?: ReactNode;
  description?: ReactNode;
  category?: {
    value?: ReactNode;
    url?: string;
  };
  button?: {
    value?: ReactNode;
    url?: string;
  };
  share?: ReactNode;
  image?: { src?: string; alt?: string };
}

export const StandaloneShowHero: StandaloneComponent<StandaloneShowHeroProps> = ({
  headline,
  description,
  share,
  category,
  button,
  image,
  ...props
}) => {
  const actionsModalRef = useRef<HTMLDialogElement>(null);

  return (
    <>
      <ShowHero {...props}>
        <ShowHero.Content>
          {category?.value && category.url && (
            <ShowHero.Category>
              <ShowHero.Tag
                value={category?.value}
                link={{ href: category?.url || `etikett/${category?.value}` }}
                options={mergeOptions({ variant: 'text' } as const, props?.options?.$tag)}
              />
            </ShowHero.Category>
          )}
          {headline && <ShowHero.Headline>{headline}</ShowHero.Headline>}
          {button && (
            <ShowHero.Button>
              <Button
                link={{ href: button.url }}
                content={
                  <>
                    <Icon
                      name="arrowFilled"
                      options={{
                        size: undefined,
                        className: 'w-3.5 h-3.5',
                      }}
                    />
                    {button.value}
                  </>
                }
              />
            </ShowHero.Button>
          )}
          {description && <ShowHero.Description>{description} </ShowHero.Description>}
          <Video.Actions>
            <Video.Actions.Share onClick={() => actionsModalRef.current?.showModal()}>{share}</Video.Actions.Share>
          </Video.Actions>
        </ShowHero.Content>
        {image && (
          <ShowHero.Image>
            <NextImage priority src={image.src || ''} alt={image.alt || ''} fill />
          </ShowHero.Image>
        )}
      </ShowHero>
      <Video.Actions.Modal ref={actionsModalRef} onClick={() => actionsModalRef.current?.close()} />
    </>
  );
};
