/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "list_item_teaser_group_variant_default": "",
  "list_item_teaser_group_size_default": "",
  "list_item_teaser_group_colors_default": "",
  "list_item_teaser_image_variant_default": "",
  "list_item_teaser_image_size_default": "!mx-0",
  "list_item_teaser_image_colors_default": "",
  "list_item_teaser_caption_variant_default": "hidden",
  "list_item_teaser_caption_size_default": "",
  "list_item_teaser_caption_colors_default": "",
  "list_item_teaser_headline_variant_default": "",
  "list_item_teaser_headline_size_default": "container-col-sm-8:text-headline-sm sm:text-headline-sm sm:container-col-lg-4:text-headline-sm sm:container-col-lg-7:text-headline-sm",
  "list_item_teaser_headline_colors_default": "",
  "list_item_teaser_description_variant_default": "hidden",
  "list_item_teaser_description_size_default": "text-body-xs sm:text-body",
  "list_item_teaser_description_colors_default": "",
  "list_item_teaser_variant_default": "grid grid-cols-2 sm:grid-cols-1",
  "list_item_teaser_size_default": "gap-2",
  "list_item_teaser_colors_default": "",
  "list_item_variant_default": "",
  "list_item_size_default": "",
  "list_item_colors_default": "",
  "list_variant_default": "grid sm:grid-cols-2 md:grid-cols-4",
  "list_colors_default": "",
  "list_size_default": "gap-3",
  "headline_variant_default": "block uppercase",
  "headline_colors_default": "",
  "headline_size_default": "mb-2 text-headline-sm sm:text-headline-md",
  "variant_default": "relative",
  "colors_default": "bg-white",
  "size_default": "p-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RecommendedArticles");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;