import { ArticleHeader } from '@/components/ArticleHeader';
import { BylineProps } from '@/components/Byline';
import { useAppState } from '@/hooks/useAppState';
import { useStable } from '@/hooks/useStable';
import { LabradorComponent } from '@/types/component';

import { Icon } from '@/components/Icon';
import { BYLINE_TYPE } from 'base/components/Byline';
import { getB2BVideoInfo, getVideoOptions } from 'base/components/JwVideo/Article/utils/dataParser';
import { authorNameToSlug } from 'lib/labrador/author-page/slug';
import { findAllOfType } from 'lib/labrador/content-operations/findBy';

export const LabradorArticleHeader: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const {
    format = 'DD MMM, YYYY',
    publishedDatetime,
    updatedDatetime,
    enabledAuthors,
    photoByline,
    extraBylineLabel,
    extraBylineContent,
    contactUsLink,
  } = data ?? {};

  const labradorImage = descendants?.find(({ type }) => type === 'labradorImage')?.data;
  const headerVideo = descendants?.find(({ type }) => type === 'jwVideo')?.data;

  const videoProps =
    headerVideo && !labradorImage
      ? {
          videoData: { ...getVideoOptions(headerVideo), title: '' },
          showDescription: headerVideo.enableDesc,
          b2bVideo: getB2BVideoInfo(headerVideo),
          playerSrc: headerVideo.playerSrc,
          videoPageLink: headerVideo.teaserLink,
        }
      : undefined;

  const { imageUrl, alt, altText, caption, byline, showCaption } = labradorImage ?? {};

  const src = imageUrl?.[device] as string;

  const bylines: BylineProps[] = findAllOfType(descendants, 'byline').map(({ data: byline, children }) => {
    const { firstName, lastName } = byline ?? {};

    const { imageUrl } = children?.[0]?.data ?? {};

    const author = [firstName, lastName].filter(Boolean).join(' ');

    return {
      type: BYLINE_TYPE.AUTHOR,
      author,
      image: imageUrl?.[device],
      authorPageSlug: enabledAuthors?.includes?.(author) && authorNameToSlug(author),
    };
  });

  if (photoByline) {
    bylines.push({ type: BYLINE_TYPE.PHOTOGRAPHER, author: photoByline });
  }

  if (extraBylineContent) {
    bylines.push({ type: BYLINE_TYPE.FREE, author: extraBylineContent, text: extraBylineLabel });
  }

  const stableProps = useStable({
    category: { value: data.category, url: data.categoryUrl },
    contactUsLink: contactUsLink && {
      ...contactUsLink,
      content: (
        <>
          <Icon name="envelope" options={{ size: 'medium' }} /> {contactUsLink.content}
        </>
      ),
    },
    bylines,
    image: src
      ? { src, alt: alt || altText, byline, caption: showCaption && caption, options: { priority: true } }
      : undefined,
    datetime: { publishedDatetime, updatedDatetime, format },
    tags: data.tags?.map((tag: string) => ({
      value: tag,
    })),
    video: videoProps,
  });

  return (
    <ArticleHeader
      headline={data.title}
      description={data.subtitle}
      data-hostpath={data.hostpath}
      data-category={data.category}
      {...stableProps}
    />
  );
};
