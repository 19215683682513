import { Button } from '@/components/Button';
import { getUrl } from '@/utils/getUrl';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneBanner } from 'base/components/Banner';
import Tracking from 'base/components/TrackingComponent';
import BackgroundImage from 'base/public/image/banner-games-image.svg';
import getConfig from 'modules/config';

const { frontPagePathname } = getConfig('keesingGames');
const ctsUrl = getUrl(frontPagePathname);
const ctsAttrs = withTrackingAttributes({
  category: 'promotion_banner',
  name: 'teaser',
  url: ctsUrl ? ctsUrl.toString() : frontPagePathname,
  extraInfo: [{ campaign: 'keesing_games' }],
});

export const HantStandaloneBanner: typeof StandaloneBanner = (props) => {
  const backgroundImageMemo = <BackgroundImage />;
  const contentMemo = (
    <>
      <h1>Nyhet!</h1>
      <h1>Korsord, sudoku och spel</h1>
      <Button
        link={{ href: frontPagePathname }}
        options={{ size: 'large', colors: 'secondary', className: 'self-start' }}
        content={'Klicka här för att spela'}
      />
    </>
  );

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneBanner content={contentMemo} backgroundImage={backgroundImageMemo} {...props} />
    </Tracking>
  );
};
