import { ButtonProps } from '@/components/Button';
import { TimerProps } from '@/components/Timer';
import { useStableProps } from '@/hooks/useStable';
import { ClassNameProvider } from '@/styles/Confirmation';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Confirmation } from './Confirmation';

interface ConfirmationBannerProps {
  header?: ReactNode;
  caption?: ReactNode;
  contacts?: ReactNode;
}
export interface StandaloneConfirmationProps extends StandaloneComponentProps<typeof Confirmation> {
  title?: ReactNode;
  banner?: ConfirmationBannerProps;
  timer?: TimerProps;
  button?: ButtonProps;
}

export const StandaloneConfirmation: StandaloneComponent<StandaloneConfirmationProps> = ({
  title,
  banner,
  button,
  timer,
  options,
  ...props
}) => {
  const { $title, $banner: bannerProps, $button, ...$base } = options ?? {};
  const { $header, $caption, $timer, $contacts, ...$banner } = bannerProps ?? {};

  const stableTimerProps = useStableProps(timer, $timer);
  const stableButtonProps = useStableProps(button, $button);

  return (
    <ClassNameProvider value={$base}>
      <Confirmation {...$base} {...props}>
        <Confirmation.Title {...$title}>{title}</Confirmation.Title>
        <Confirmation.Banner {...$banner}>
          {banner?.header && <Confirmation.Banner.Header {...$header}>{banner?.header}</Confirmation.Banner.Header>}
          {banner?.caption && (
            <Confirmation.Banner.Caption {...$caption}>{banner?.caption}</Confirmation.Banner.Caption>
          )}
          {banner?.contacts && (
            <Confirmation.Banner.Contacts {...$contacts}>{banner?.contacts}</Confirmation.Banner.Contacts>
          )}
          <Confirmation.Banner.Timer {...stableTimerProps} />
        </Confirmation.Banner>
        <Confirmation.Button {...stableButtonProps} />
      </Confirmation>
    </ClassNameProvider>
  );
};
