import { tw } from '@/utils/tw';

const Banner = tw.theme({
  slots: {
    base: [
      'bg-primary-600',
      'text-white',
      'w-full',
      'sm:pe-40',
      'sm:py-7',
      'sm:pl-7',
      'sm:gap-y-4',
      'gap-y-2',
      'pe-8',
      'py-4',
      'pl-4',
      'flex',
      'flex-col',
      'items-start',
      'not-prose',
      'relative',
      'z-1',
      'overflow-hidden',
    ],
    content: ['flex', 'flex-col', 'gap-y-1', '[&_a]:mt-3', '[&_h1]:text-headline-lg', '[&_h1]:sm:text-headline-xl'],
    backgroundImage: [
      'text-primary-700',
      'h-42',
      'w-42',
      'absolute',
      '-z-1',
      '-bottom-11',
      '-right-12',
      'sm:bottom-1.5',
      'sm:right-1.5',
    ],
  },
});

export default Banner;
