import { HantStandaloneNewsletterSignup } from '@/hant/components/NewsletterSignup';
import { LabradorComponent } from '@/types/component';

export const HantLabradorNewsletterSignup: LabradorComponent = ({ type, data, meta }) => {
  return (
    <HantStandaloneNewsletterSignup
      headline={data.headline}
      description={data.infoText}
      footer={data.integrityText}
      successModal={{
        content: data.successText,
      }}
      submitButton={{
        content: data.submitLabel,
      }}
    />
  );
};
