import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
  base: `w-35`,
});

const ArticleTeaserBodytext = tw.theme({
  slots: {
    base: ``,
    category: `text-body-sm`,
    content: `not-prose flex gap-3`,
    description: `text-headline-sm`,
    footer: `text-headline-sm`,
    group: `flex flex-col gap-0.5`,
    headline: `text-headline-xs`,
  },
  variants: {
    variant: {
      first: {
        base: ``,
        category: ``,
        content: `bg-primary-900 rounded p-3 text-white`,
        description: ``,
        footer: ``,
        group: ``,
        headline: `after:bg-primary-700 mb-0.5 flex items-center whitespace-nowrap uppercase after:ml-3 after:block after:h-0.5 after:w-full`,
      },
      second: {
        base: ``,
        category: `hidden`,
        content: `border-y-1 border-y-black py-3`,
        description: ``,
        footer: ``,
        group: `justify-center`,
        headline: `hidden`,
      },
      third: {
        base: ``,
        category: ``,
        content: `border-l-primary-900 rounded-r border-l-4 bg-gray-100 p-3`,
        description: ``,
        footer: ``,
        group: ``,
        headline: `mb-0.5 uppercase`,
      },
      fourth: {
        base: ``,
        category: ``,
        content: `p-3 shadow-[0px_0px_10px_2px] shadow-black/25`,
        description: ``,
        footer: ``,
        group: ``,
        headline: `hidden`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
