import { tw } from '@/utils/tw';
import { default as BaseDirektTheme } from 'base/components/Direkt/theme';

const { Blip } = BaseDirektTheme;

const DirektTheme = tw.theme({
  extend: BaseDirektTheme,
  slots: {
    group: 'mb-0',
    heading: 'mb-0 mt-2',
  },
});

export default Object.assign(DirektTheme, { Blip });
