import { mergeDeep } from '@/utils/mergeDeep';
import { StandaloneRecommendedArticles } from 'base/components/RecommendedArticles';

export const HantStandaloneRecommendedArticles: typeof StandaloneRecommendedArticles = ({
  headline,
  articles,
  ...props
}) => {
  articles = articles?.map((article) =>
    mergeDeep(article, {
      options: {
        className: 'grid-cols-[130px_1fr]',
        $group: {
          className: '!p-0 mt-0 sm:mt-2',
        },
        $image: {
          className: 'aspect-[40/21]',
        },
      },
    }),
  );

  return (
    <StandaloneRecommendedArticles
      articles={articles}
      headline={headline ?? 'REKOMMENDERAD LÄSNING FÖR DIG'}
      {...props}
    />
  );
};
