import { ArticleTeaserDefault } from '@/components/ArticleTeaser/Default';
import { Link } from '@/components/Link';
import { LoopAround } from '@/components/LoopAround';
import { OptionalLinkProps } from '@/components/OptionalLink';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { slugify } from '@/utils/slugify';
import { stripMultiLineHtml } from '@/utils/stripHtml';

import { findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { useRef } from 'react';

export const HantLabradorArticleTeaserDefault: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();
  const ref = useRef<HTMLDivElement>(null);

  const { prominentTag, badgeData, title, publishedUrl, kicker, category } = data ?? {};
  const { name: badgeName } = badgeData ?? {};
  const { nativeAdLabel, teaserIndex } = meta ?? {};

  const image = findFirstOfType(descendants, 'labradorImage');
  const imageUrl = image?.data?.imageUrl?.[device];
  const imageSearchParams = new URLSearchParams(imageUrl || '');
  const imageWidth = imageSearchParams.get('width');
  const imageHeight = imageSearchParams.get('height');
  const imageAspectRatio = imageWidth && imageHeight ? `${imageWidth}/${imageHeight}` : '2/1';

  const isPriority = (teaserIndex ?? 0) < 4;

  const badgeHeader = badgeName && (
    <LoopAround fragment={<span>{badgeName}</span>} options={{ variant: 'secondary' }} />
  );

  const nativeAdLabelCaption: OptionalLinkProps = nativeAdLabel && {
    href: `etikett/${slugify(nativeAdLabel, { replacement: ' ' })}`,
  };

  const nativeAdLabelHeader = nativeAdLabel && (
    <Link
      {...nativeAdLabelCaption}
      content={nativeAdLabel}
      options={{
        className: 'text-headline-xs flex h-full grow items-center justify-center bg-black normal-case text-white',
      }}
    />
  );

  const header = nativeAdLabelHeader || badgeHeader || null;
  const headline = stripMultiLineHtml(title);

  const categoryCaption: OptionalLinkProps = {
    href: slugify(category) === 'noje' ? '/' : slugify(category),
  };

  const prominentTagCaption: OptionalLinkProps = prominentTag && {
    href: `etikett/${prominentTag}`,
  };

  return (
    <div ref={ref}>
      <ArticleTeaserDefault
        data-index={teaserIndex}
        header={header}
        headline={headline}
        caption={nativeAdLabel || prominentTag || category}
        kicker={kicker}
        links={{
          article: {
            href: publishedUrl,
          },
          caption: nativeAdLabelCaption || prominentTagCaption || categoryCaption,
        }}
        image={{
          src: imageUrl,
          options: {
            priority: isPriority,
            style: {
              aspectRatio: imageAspectRatio,
            },
          },
        }}
      />
    </div>
  );
};
