import { useClassName } from '@/styles/VideoReels';
import { ComponentProps, ComponentWithRef } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import React from 'react';

const GenericComponent = GenericComponentFactory({ useClassName });

type VideoReelsProps = ComponentProps<'div'>;

const VideoReelsComponent: ComponentWithRef<HTMLDivElement, VideoReelsProps> = React.forwardRef(
  function VideoReelsComponent({ children, ...props }, ref) {
    const className = useClassName('', props);

    return (
      <div ref={ref} {...mergeProps({ className }, props)}>
        {children}
      </div>
    );
  },
);

export const VideoReels = Object.assign(VideoReelsComponent, {
  Headline: GenericComponent({ as: 'h4', styles: { key: 'headline' } }),
});
