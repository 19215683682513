import { Image } from '@/components/Image';
import { LabradorComponent } from '@/types/component';
import { mapLabradorImageToStandaloneImage } from '@/utils/image';

export const HantLabradorImage: LabradorComponent = ({ type, data, meta, descendants }) => {
  const mappedData = mapLabradorImageToStandaloneImage({ type, data, meta, children: descendants });

  return (
    <Image
      src={mappedData.src}
      alt={mappedData.alt}
      caption={data.showCaption && mappedData.caption}
      byline={data.showCaption && mappedData.byline}
    />
  );
};
