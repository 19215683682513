import { tw } from '@/utils/tw';
import BaseArticleDateTheme from 'base/components/ArticleDate/theme';

const ArticleDate = tw.theme({
  extend: BaseArticleDateTheme,
  slots: {
    date: `text-gray-700 text-input-sm sm:text-input-md`,
    updatedDate: `text-gray-700 text-input-sm sm:text-input-md`,
  },
});

export default ArticleDate;
