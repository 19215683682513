import { StandaloneInstagramArticlesList } from 'base/components/InstagramArticlesList';

export const HantStandaloneInstagramArticlesList: typeof StandaloneInstagramArticlesList = ({ articles, ...props }) => {
  articles = articles?.slice(0, articles?.length - ((articles?.length ?? 0) % 3));

  return (
    <StandaloneInstagramArticlesList
      headline="Instagram"
      description="Här hittar du våra senaste Instagram-inlägg. Klicka på en bild för att komma till artikeln"
      articles={articles}
      {...props}
    />
  );
};
